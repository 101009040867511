.account {
    max-width: 43rem;

    @media($tablet) {
        margin: auto;
    }

    &__row {
        display: flex;
        margin-bottom: 2rem;
        align-items: center;

        @media($mobile) {
            flex-direction: column;
            align-items: flex-start;

	        &--image {
	            .account__label {
	                display: none;
	            }
	        }
        }

    }

    &__label {
        width: 16rem;
        margin-right: 1.5rem;
        color: $main-light;

        @media($mobile) {
            margin-bottom: 1rem;
        }

    }

    &__field {
        flex-grow: 1;

        @media($mobile) {
            width: 100%;
        }

    }

    .btn {
        width: 100%;
    }

    &__avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__avatar-img {
        margin-bottom: 1rem;
    }


}
