.card {
	box-shadow: 0 1px 10px rgba(#B2DFDB, .41);
	border-radius: 1.2rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	cursor: pointer;

	&__header{
		height: 4rem;
		font-size: 1.7rem;
		font-weight: 700;
		padding: 0 1.5rem;
		position: relative;
		display: flex;
		align-items: center;

		&:before {
			content: "";
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background: $main-lighten;
			margin-right: 1rem;

		}
	}

	&__label {
		margin-left: auto;
		color: $primary;
		font-size: 1.3rem;
		font-weight: 400;
		font-style: italic;
	}
	&__descr{
		padding: 1.5rem;
		background: rgba($main-lighten, .12);
	}
	&__title{
		font-size: 1.7rem;
		margin-bottom: 1rem;
		font-weight: 700;
	}
	&__list{
		font-size: 1.3rem;
	}
	&__list-item{}
	&__price {
		padding: 0 1.5rem;
		text-align: center; 
		min-height: 8.4rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

	}
	&__price-title{
		font-size: 3rem;
		font-weight: 700;
		line-height: 1;
		display: flex;
		align-items: baseline;
		justify-content: center;

		svg {
			position: relative;
			top: .2rem;
		}
	}
	&__price-descr{
		color: $main-light;
		font-size: 1.2rem;
	}

	&__discount {
		font-size: 1.3rem;
		color: $error;
		font-weight: 700;
		font-style: italic;
	}
	&__footer{
		padding: 1rem;
		background: $secondary;
		font-size: 1.6rem;
		font-weight: 700; 
		/*white-space: nowrap;*/
		margin-top: auto;
		text-align: center;
	}
	&.is-active {
		border: 1px solid $primary-light;
		.card__footer {
			background: $primary;
		}

		.card__header:before {
			background: url(../svg_icons/check.svg);
			background-size: cover;
		}

	}
}