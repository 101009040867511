.sidebar {
    flex-basis: 27rem;
    padding: 2rem 0;
    box-shadow: 0 1px 10px rgba(#B2DFDB, .41);
    border-radius: 1.2rem;
    flex-shrink: 0;


    @media($tablet) {
        flex-basis: auto;
        position: absolute;
        width: 100%;
        max-width: 50%;
        top: 20px;
        
        &.is-expand {
            z-index: 1;
            background: $white;
             .sidebar__item.is-active:after {
                    transform: translateY(-50%) rotate(-275deg)
                }

             .sidebar__item:not(.is-active) {
                display: flex;
             }   


        }

        &:not(.is-expand) .sidebar__item.is-active{
            margin-bottom: 0;
        }
    }


    @media($mobile) {
        max-width: 100%;
    }

    &__list {

        @media($tablet) {
            display: flex;
            flex-direction: column;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 0 2rem;
        margin-bottom: 2rem;
        min-height: 6rem;
        align-items: center;
        cursor: pointer;

        @media($tablet) {
            padding-right: 5.0rem;
            position: relative;
        }
        

        &.is-active {

            @media($tablet) {
                order: -1;
                
                &:after {
                    content: "";
                    position: absolute;
                    right: 1.0rem;
                    top: 50%;
                    transform: translateY(-50%) rotate(-90deg);
                    transition: transform .2s;
                    width: 2rem;
                    height: 2rem;
                    display: block;
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath fill='transparent' d='M0 0h18v18H0z'/%3e%3cpath d='M5.393 9.594l5.16 5.16a.84.84 0 1 0 1.188-1.188L7.175 9l4.566-4.565a.84.84 0 0 0-1.189-1.189l-5.16 5.16a.84.84 0 0 0 0 1.188z' fill='%23cecfd1'/%3e%3c/svg%3e");
        
                }
            }


            .icon {
                &.active {
                    display: block;
                }

                &:not(.active) {
                    display: none;
                }
            }
        }

        &:not(.is-active) {
            @media($tablet) {
                display: none;
            }
        }
    }

    &__title {
        font-weight: 700;
    }


    &__descr {
        font-size: 14px;
    }

    &__icon {
        width: 6rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
	    margin-right: 2rem;
        flex-shrink: 0;

        .icon {
            display: block;
            width: 100%;
            height: 100%;

            &.active {
                display: none;
            }
        }

        &:hover {
            
        }

    }


    &__card {
        
        margin-bottom: 2rem;


    }

    &--ext {
        flex-basis: 36rem;
        box-shadow: none;
        padding: 0;

        @media($tablet) {
            position: static;
            margin-top: -10rem;
            display: contents;
        }

        .sidebar__card:not(.is-active) {
            @media($tablet) {
                order: 2;
                margin-top: 3rem;
            }
        }


        .sidebar__card.is-active {
            @media($tablet) {
                margin-top: -13rem;
                margin-bottom: 0;
                box-shadow: none;
                padding-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: 0;
            }
        }




    }
}
