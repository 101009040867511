.pagination {
	display: flex;
	&__btn{
		width: 3.8rem;
		height: 3.4rem;
		background-color: transparent;
		border: 1px solid $main-lighten;
		border-radius: .4rem;
		outline: none; 
		background-repeat: no-repeat;
		background-position: center; 
		&--prev{
			margin-right: 2rem;
			    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath fill='transparent' d='M0 0h18v18H0z'/%3e%3cpath d='M5.393 9.594l5.16 5.16a.84.84 0 1 0 1.188-1.188L7.175 9l4.566-4.565a.84.84 0 0 0-1.189-1.189l-5.16 5.16a.84.84 0 0 0 0 1.188z' fill='%23cecfd1'/%3e%3c/svg%3e");
			    &:hover {
				    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath fill='transparent' d='M0 0h18v18H0z'/%3e%3cpath d='M5.393 9.594l5.16 5.16a.84.84 0 1 0 1.188-1.188L7.175 9l4.566-4.565a.84.84 0 0 0-1.189-1.189l-5.16 5.16a.84.84 0 0 0 0 1.188z' fill='%2303b2a8'/%3e%3c/svg%3e");
			    }


			    &[disabled],
			    &.is-disabled {
			        cursor: not-allowed;
			        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath fill='transparent' d='M0 0h18v18H0z'/%3e%3cpath d='M5.393 9.594l5.16 5.16a.84.84 0 1 0 1.188-1.188L7.175 9l4.566-4.565a.84.84 0 0 0-1.189-1.189l-5.16 5.16a.84.84 0 0 0 0 1.188z' fill='%23cecfd1'/%3e%3c/svg%3e");

			    }

		}
		&--next{
			margin-left: 2rem;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath d='M12.567 9.594l-5.16 5.16a.84.84 0 1 1-1.188-1.188L10.784 9 6.219 4.434a.84.84 0 0 1 1.188-1.188l5.16 5.16a.84.84 0 0 1 0 1.188z' fill='%23CECFD1'/%3e%3c/svg%3e");
			
			&:hover {
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath d='M12.567 9.594l-5.16 5.16a.84.84 0 1 1-1.188-1.188L10.784 9 6.219 4.434a.84.84 0 0 1 1.188-1.188l5.16 5.16a.84.84 0 0 1 0 1.188z' fill='%2303b2a8'/%3e%3c/svg%3e");
			}


			&[disabled],
			&.is-disabled {
			    cursor: not-allowed;
			    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath d='M12.567 9.594l-5.16 5.16a.84.84 0 1 1-1.188-1.188L10.784 9 6.219 4.434a.84.84 0 0 1 1.188-1.188l5.16 5.16a.84.84 0 0 1 0 1.188z' fill='%23CECFD1'/%3e%3c/svg%3e");
			}
		}
	}

	&__page {
		background: transparent;
		border: none;
		display: flex;
		padding: 0 1rem;
		border-radius: .4rem;
		outline: none;
		color: $main-light;
		align-items: center;

		&:hover {
			color: $primary;
		}

		&.is-current{
			background: $primary;
			color: $white;
		}
	}
}
