.header {
    color: $white;

    &.is-active {

	    @media($mobile) {
	        .header__nav {
	            transform: translateY(0);
	        }

	        .header__menu-shadow {
	        	display: block;
	        }
	    }

    }

    &__top {
        display: flex;
        justify-content: space-between;
        height: 6rem;
        align-items: center;
        padding: 0 2rem;

        @media($tablet) {
            height: 5rem;
            margin: 0 -2rem;
        }


        @media($mobile) {
            justify-content: flex-start;
            position: relative;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                background: inherit;
                left: 0;
                top: 0;
                z-index: 3;
            }
        }

    }


    &__menu-shadow {
        display: none;
        position: fixed;
        background: $black;
        opacity: .5;
        z-index: 1;
        top: 5.1rem;
        bottom: 0;
        left: 0;
        right: 0;
    }


    &__menu-toggler {
        margin-right: 2.2rem !important;
        position: relative;
        z-index: 3;
        display: none !important;

        @media($mobile) {
            display: block !important;
        }
    }

    &__logo {
        @media($tablet) {
            display: flex;
        }


        @media($mobile) {
            position: relative;
            z-index: 3;
        }

    }


    &__nav {
        @media($mobile) {
            position: absolute;
            top: 5.1rem;
            width: 100%;
            left: 0;
            background: $primary;
            z-index: 2;
            padding-top: 3rem;
            padding-bottom: 4rem;
            padding-left: 5.8rem;
            transform: translateY(-150%);
            transition: transform .3s;
        }

    }

    &__nav-list {
        display: flex;

        @media($mobile) {
            flex-direction: column;

            li .btn {
                font-size: 1.8rem;
                margin-bottom: 1.5rem;
            }

            .header__nav-account .btn {
                font-size: 1.6rem;
            }
        }
    }

    &__nav-account {

        display: flex;
        align-items: center;
        margin-left: 2rem;

        @media($mobile) {
            margin-left: 0;
        }

        img {
            flex-shrink: 0;
            border-radius: 50%;
            border: 3px solid $secondary;
            width: 3rem;
            height: 3rem;
            display: block;
            margin-right: 1rem;
        }
    }


    &__nav-logout {

        @media($mobile) {
            &::after {
                content: attr(data-content);
                margin-left: 2rem;
                font-size: 1.6rem;
            }

        }
    }

    &__controls {
        padding: 0 2rem;
        height: 7rem;
        display: flex;
        align-items: center;
        border-radius: 0 0 2rem 2rem;
        border-top: 1px solid $primary-dark;

        @media($tablet) {
            margin: 0 -2rem;
            border-radius: 0;
        }

        @media($mobile) {
            height: auto;
            flex-wrap: wrap;
            padding-bottom: 2rem;
        }


    }

    &__controls-title {
        font-size: 3rem;
        margin-right: 6.2rem;

        @media($tablet) {
            font-size: 24px;
            display: flex;
            align-items: center;
        }

        @media($mobile) {
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            margin-right: 0;
        }


        .btn {
            margin-left: 1.5rem;
            position: relative;
            top: -.5rem;

            @media($tablet) {
                top: .2rem;
            }


            @media($mobile) {
                flex-shrink: 0;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;


        @media($mobile) {
            max-width: 100%;
            overflow: auto;
        }

        .btn {
            @media($mobile) {
                height: 4.4rem;
                width: 4.4rem;
            }
        }


        .btn--pill {
            background: $primary-dark;
            box-shadow: none;


            @media($mobile) {
                white-space: nowrap;
                height: 3.2rem;
                width: auto;
            }


            &:active,
            &.is-active {
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
                background: $secondary;
            }
        }

        li {
            margin-right: 2rem;

            @media($mobile) {
                margin-right: 1.5rem;
            }
        }

    }
    &__action_buttons {
        margin-left: auto;
    }
    &__add {
        @media($mobile) {
            font-size: 0;
            height: 4.4rem;
            border-radius: 50%;
            width: 4.4rem;
            padding: 0;
            span {
                margin: 0;
            }
        }
    }
}
