.lesson {
	&__view{
		border: 1px solid $primary-light;
		border-radius: 1.2rem;
		padding: 2rem;
		background: $primary-lighten;
		text-align: center;
		margin-bottom: 2rem;

		@media($mobile) {
		    padding: 2rem 0 1.5rem;
		}

	}
	&__video {

		display: inline-flex;
		border-radius: .4rem;
		overflow: hidden;
	}
	&__title {
		font-size: 1.7rem;
		font-weight: 700;
		margin-bottom: 2rem;

		@media($mobile) {
		    padding-left: 2rem;
		    padding-right: 2rem;
		}

	}
	&__nav{
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media($mobile) {
		    flex-direction: column;
		     .pagination {
			    order: -1;
			}

			.link {
			    margin-top: 1.5rem;
			}
		}


	}
}