@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin center($position) {
    position: absolute;

    @if $position == 'vertical' {
        top: 50%;
        transform: translateY(-50%);
    }
    @else if $position == 'horizontal' {
        left: 50%;
        transform: translate(-50%);
    }
    @else if $position == 'both' {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin iblock($align: initial) {
    display: inline-block;
    vertical-align: $align;
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin fontFace($family, $src, $style: normal, $weight: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$src}.woff2') format('woff2'),
             url('#{$src}.woff') format('woff');
        font-style: $style;
        font-weight: $weight;
    }
}

@mixin breakpoint($width) {
    @media only screen and (max-width: $width) {
        @content;
    }
}



@mixin row($gap) {
    margin: 0 #{-$gap/2};
    display: flex;
    flex-wrap: wrap;
}


@mixin col($cols, $gap) {
    margin: $gap/2;
    width: calc(#{100%/$cols} - #{$gap});
}
