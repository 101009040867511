.login-page {
    display: flex;
    height: 100%;
    flex-grow: 1;

    @media($mobile) {
        margin: 0 -2.0rem;
    }

    &__inner {
        flex-grow: 1;
        display: flex;
        max-width: 102.4rem;
        margin: auto;
        box-shadow: 0 3px 20px rgba(0, 0, 0, .11);
        border-radius: 1rem;
        overflow: hidden;
        min-height: 6rem;
        width: 100%;

        @media($tablet) {
            flex-direction: column;
            max-width: 50.8rem;
        }

        @media($mobile) {
            width: 100%;
            max-width: 100%;
            border-radius: 0;
            min-height: 100vh;
        }

    }


    &__logo {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media($tablet) {
            width: 100%;
            height: 20.8rem;

            img {
                width: 12.8rem;
            }
        }

        @media($mobile) {
            height: 5.0rem;
            background-image: url('../img/logo-min.png');
            background-position: center;
            background-repeat: no-repeat;

            img {
                display: none;
                width: auto;
                height: 2.6rem;
            }
        }
    }

    &__form {
        width: 50%;
        padding: 6rem 9rem 4.4rem;

        @media($tablet) {
            width: 100%;
            padding: 6rem 8.4rem 4.4rem;
        }

        @media($mobile) {
            padding: 3rem 3rem 6rem;
        }
    }

    &__title {
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
        line-height: 1;
        margin-bottom: 2rem;


        @media($mobile) {
            font-size: 2.4rem;
        }

        +.input {
            margin-top: 2rem;
        }
    }

    &__descr {
        text-align: center;
        margin-bottom: 3rem;
    }

    &__link {
        margin-bottom: 3rem;
        text-align: right;

        .link {
            display: inline-block;
        }
    }

    &__row {
        margin-bottom: 3rem;
    }

    .btn {
        width: 100%;
    }

    .label {
        margin-bottom: 3rem;
    }

    .link {
        font-size: 1.6rem;
    }

    &__info {
        margin-top: 4.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: 1.4rem;
    }
}
