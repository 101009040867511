$container-width: 1170px;

/* COLORS */

$black: #000;
$white: #fff;
$main: #212121;
$main-light: #757575;
$main-lighten: #CECFD1;

$primary-dark: #03A097;
$primary: #03B2A8;
$primary-light: #B2DFDB;
$primary-lighten: #FBFFFF;

$secondary-dark: #EBA500;
$secondary: #FEB811;
$secondary-light: #FFF3D6;

$error: #FE202F;
/* MEDIA QUERIES */

/* MEDIA QUERIES */
$tablet: unquote("max-width: 1200px");
$mobile: unquote("max-width: 767px");

$onlydesk: unquote("min-width: 1280px");
