*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    font-size: 62.5%;
}

body {
    line-height: 1.5;
    color: $main;
}

address {
    font-style: normal;
}

button {
    cursor: pointer;
    outline: none;
}

img {
    height: auto;
    max-width: 100%;
}

video {
    max-width: 100%;
}

.nounderline {
    text-decoration: none;
}

.nounderline:hover {
    text-decoration: none;
}

label {
    cursor: pointer;
    user-select: none;
}

textarea {
    resize: vertical;
    /*height: auto !important; */
}

ul {
    @include list-reset;
}

@include placeholder {
    text-overflow: ellipsis;
}

input[placeholder] {
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}*/

:focus::-webkit-input-placeholder {
    color: transparent;
}

:focus::-moz-placeholder {
    color: transparent;
}

:focus:-moz-placeholder {
    color: transparent;
}

:focus:-ms-input-placeholder {
    color: transparent;
}

.responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $container-width;
}

.site-wrapper {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    font-size: 1.6rem;
    min-height: 100vh;
    // min-width: 1100px;
    padding: 0 2rem;
}

.site-content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.site-message {
    padding: 2rem 2rem 0;
    font-size: 1.3rem;
}

.main {
    padding-left: 2rem;
    flex-grow: 1;

    @media($tablet) {
        padding-left: 0;
    }


    &__header {
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @media($tablet) {
            position: absolute;
            max-width: 50%;
            right: 0;
            display: flex;
            flex-direction: column;
            top: 2rem;
        }

        @media($mobile) {
            position: static;
            max-width: 100%;
            align-items: center;
        }


        &--account {
            max-width: 43rem;

            @media($tablet) {
                position: static;
                max-width: 43rem;
                margin: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 3rem;
            }

            @media($mobile) {
                flex-direction: column;

            }

            .h1 {
                display: block !important;

                @media($mobile) {
                    margin-bottom: 1.5rem !important;

                }
            }

        }

        .h1 {
            margin-bottom: 0;

            @media($tablet) {
                display: none;
            }
        }

        .switcher {
            margin-left: auto;
            margin-right: 3rem;

            @media($tablet) {
                margin-right: 0;
                margin-bottom: 2.5rem;
            }

            @media($mobile) {
                margin: 0 0 2rem;
            }

        }
    }
}



.site-row {
    display: flex;
    width: 100%;
    padding: 2rem 0;
    flex-grow: 1;


    @media($tablet) {
        flex-direction: column;
        padding-top: 150px;
        position: relative;
    }

    &--account {
        @media($tablet) {
            flex-direction: column;
            padding-top: 3rem;

            .sidebar {
                display: none;
            }
        }
    }


}

.bg-cover {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



.txt {
    &--black {
        color: $black;
    }

    &--white {
        color: $white;
    }

    &--main {
        color: $main;
    }

    &--main-light {
        color: $main-light;
    }

    &--main-lighten {
        color: $main-lighten;
    }

    &--primary-dark {
        color: $primary-dark;
    }

    &--primary {
        color: $primary;
    }

    &--primary-light {
        color: $primary-light;
    }

    &--primary-lighten {
        color: $primary-lighten;
    }

    &--secondary-dark {
        color: $secondary-dark;
    }

    &--secondary {
        color: $secondary;
    }

    &--secondary-light {
        color: $secondary-light;
    }
}

.bg {
    &--black {
        background-color: $black;
    }

    &--white {
        background-color: $white;
    }

    &--main {
        background-color: $main;
    }

    &--main-light {
        background-color: $main-light;
    }

    &--main-lighten {
        background-color: $main-lighten;
    }

    &--primary-dark {
        background-color: $primary-dark;
    }

    &--primary {
        background-color: $primary;
    }

    &--primary-light {
        background-color: $primary-light;
    }

    &--primary-lighten {
        background-color: $primary-lighten;
    }

    &--secondary-dark {
        background-color: $secondary-dark;
    }

    &--secondary {
        background-color: $secondary;
    }

    &--secondary-light {
        background-color: $secondary-light;
    }
}


.row {
    @include row(3rem)
}

.col {
    @include col(2, 3rem)
}
