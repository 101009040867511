.lesson-list {
    &__item {
        margin-bottom: 2rem;

        @media($tablet) {
            flex-direction: column;
        }
    }
}


.lesson-btn {
    border: 1px solid $primary-light;
    border-radius: 1.2rem;
    display: flex;
    max-width: 100%;
    /*min-height: 6.4rem;*/


    &__header {
        display: flex;
        // height: 6.4rem;
        align-items: center;
        padding: 0 2rem;
        flex-wrap: wrap;
        flex-grow: 1;
        height: auto;
        min-height: 6.4rem;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;


        @media($mobile) {
            min-height: 6.4em;
            height: auto;
            flex-wrap: wrap;
        }

        &:before {
            content: "";
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: #CECFD1;
            margin-right: 2rem;
            flex-shrink: 0;

        }
    }

    &.is-done {
        .lesson-btn__header:before {
            background: url(../svg_icons/check.svg);
            background-size: cover;
        }
    }

    &__title {
        font-size: 1.7rem;
        font-weight: 700;
        position: relative;
        top: -1px;
        margin-right: 1.5rem;

        @media($mobile) {
            width: calc(100% - 6rem);

            + .lesson-btn__cnt,
            + .lesson-btn__time {
                padding-left: 4rem;
            }
        }

    }

    &__time {
        margin-left: auto;
        margin-right: 2rem;
        color: $main-light;
        font-size: 1.3rem;

        @media($mobile) {
            margin-left: 0;
        }
    }


    &__links {
        display: flex;
        width: 100%;
        padding-left: 4rem;


        .link:first-child {
            margin-right: 2rem;
        }

        @media($mobile) {
            flex-direction: column;
            align-items: flex-start;

            .link {
                margin-top: 1rem;
                font-size: 1.3rem;
            }

        }
    }


    &__controls {
        display: flex;
        padding: 0 2rem 2rem 6rem;
        display: none;

        .link:not(.lesson-btn__retry) {
            margin-right: 2rem;
        }
    }

    &__continue {
        margin-left: auto;
        margin-right: 2rem;
        color: $secondary;
        font-size: 1.3rem;
        cursor: pointer;
        display: none;
    }

    &__retry {
        margin-left: auto;
    }

    &__cnt {
        color: $main-light;
        margin-right: 1.8rem;
        font-weight: 700;
        display: none;
    }


    &__play {
        padding: 0;
        width: 4rem;
        border: none;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        outline: none;
        background: $white;
        box-shadow: 0 3px 6px rgba(134, 139, 146, .25);
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16'%3e%3cpath d='M14 8L0 16V0z' fill='%23757575'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: 60% center;


        @media($mobile) {
            margin-left: auto;
        }

        /*
        .icon {
            position: relative;
            right: -2px;
        }
 */
        &:hover {
            background-color: $secondary;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16'%3e%3cpath d='M14 8L0 16V0z' fill='white'/%3e%3c/svg%3e");
        }

        &:active {
            background-color: $secondary-dark;
        }
    }


    &__buttons {
        align-self: center;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media($tablet) {
            align-self: flex-start;
            flex-direction: row;
            padding-left: 6rem;
        }

        @media($mobile) {
            flex-direction: column;
        }

    }


    &__control {
        height: 3.2rem;
        white-space: nowrap;
        border: 1px solid $primary;
        display: inline-flex;
        padding: 0 1rem 0 4.2rem;
        border-radius: 8px;
        align-items: center;
        font-size: 1.3rem;
        cursor: pointer;
        overflow: hidden;
        position: relative;

        &:before {
            content: '';
            height: 100%;
            width: 3.2rem;
            position: absolute;
            left: 0;
            top: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-color: $primary;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16.234' height='16.217'%3e%3cdefs%3e%3cstyle%3e.a%7bfill:%23fff;stroke:%23fff;stroke-width:.2px%7d%3c/style%3e%3c/defs%3e%3cpath class='a' d='M15.042 4.117A8 8 0 00.393 6.047a8 8 0 005.654 9.794 8.04 8.04 0 002.083.276 8 8 0 006.912-12zm-.006 5.852a7.164 7.164 0 01-13.12 1.73A7.169 7.169 0 018.105.95a7.2 7.2 0 011.866.247 7.164 7.164 0 015.066 8.774z'/%3e%3cpath class='a' d='M12.028 7.338l-5.3-3.061a.9.9 0 00-1.348.778v6.118a.9.9 0 001.348.778l5.3-3.061a.9.9 0 000-1.557zm-.429.813l-5.3 3.061a.04.04 0 01-.06-.035V5.054a.036.036 0 01.02-.035.04.04 0 01.02-.006.038.038 0 01.02.006l5.3 3.061a.04.04 0 010 .069z'/%3e%3c/svg%3e");
        }
        &:hover:before {
            background-color: $secondary;
            color: $secondary;
        }
        &:hover {
            border: 1px solid $secondary;
            color: black;
        }

        +.lesson-btn__control {
            margin-top: 1rem;

            @media($tablet) {
                margin-top: 0;
                margin-left: 2rem;
            }

            @media($mobile) {
                margin-left: 0;
                margin-top: 1rem;
            }

        }

        &--retry {
            border-color: $secondary;

            &:before {
                background-color: $secondary;
            }
        }
    }


    &.is-active {
        background: $primary-lighten;
    }


    &.is-expand {
        .lesson-btn__time {
            margin-left: 0;
        }

        .lesson-btn__continue {
            display: block;
        }

        .lesson-btn__cnt {
            display: block;
        }

        .lesson-btn__controls {
            display: flex;
        }

    }


}
