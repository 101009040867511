.payment {
    border: 1px solid $primary-light;
    padding: 2rem;
    border-radius: 1.2rem;
    width: 100%;

    @media($tablet) {
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        padding-bottom: 1rem;
    }

    &__row {
        @include row(2rem);
        flex-wrap: nowrap;

        @media($tablet) {
            margin: 0 -2rem;
            overflow: auto;
        }
    }

    &__card {
        @include col(4, 2rem);

        @media($tablet) {

            margin-left: .5rem;
            margin-right: .5rem;
            min-width: 17rem;


        }

    }

    &__info {
        margin-bottom: 1rem;
        font-size: 1.3rem;
    }

}
