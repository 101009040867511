.h1 {
    font-size: 3rem;
    margin: 0 0 2rem;
    line-height: 1.4;
    display: inline-block;

}



.input {

    height: 5rem;
    width: 100%;
    border-radius: .6rem;
    padding: 0 2rem;
    border: 1px solid $primary-light;
    outline: none;

    @include placeholder {
        color: $main-light
    }



    &[disabled],
    &.is-disabled {
        border-color: $main-lighten;
        background: $white;
        color: $main-lighten;
        cursor: not-allowed;

        @include placeholder {
            color: $main-lighten;
        }
    }

    &.is-error {
        color: $error;
        border-color: $error;

        @include placeholder {
            color: $error;
        }

    }
}

textarea.input {
	height: 8.8rem;
    padding: 1.5rem 2rem;
}

.input-err {
    color: $error;
    font-size: 1.4rem;
}




.input-password {
    position: relative;

    .input {
        padding-right: 7rem;
    }


    &__button {
        position: absolute;
        right: 0;
        height: 5rem;
        top: 0;
        width: 5rem;
        background-color: transparent;
        background-image: url(../svg_icons/eye.svg);
        background-repeat: no-repeat;
        background-position: center;
        border: none;
    }
}


.checkbox {
    position: relative;
    height: 3rem;
    width: 3rem;
    display: inline-block;
    flex-shrink: 0;

    input {
        opacity: 0;

        +i {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border: 1px solid $primary-light;
            border-radius: .6rem;
            position: absolute;

        }

        &:checked {
            +i {
                background-image: url(../svg_icons/check.svg);
                background-color: $primary;
                border: 1px solid $primary;
            }
        }


    }

}

.radio {
    position: relative;
    height: 3rem;
    width: 3rem;
    display: inline-block;

    input {
        opacity: 0;

        +i {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border: 1px solid $primary-light;
            border-radius: 50%;
            position: absolute;

        }

        &:checked {
            +i {
                border: 8px solid $primary;
                background: $white;
            }
        }


    }
}

.label {
    display: flex;
    align-items: center;

    .radio,
    .checkbox {
        margin-right: 1.5rem;
    }

}


.btn {
    cursor: pointer;
    background: $primary;
    border-radius: .6rem;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    text-decoration: none;
    border: none;
    height: 5rem;
    padding: 0 3rem;
    text-transform: uppercase;
    line-height: 1;
    transition: background-color .2s;
    outline: none;
    font-weight: bold;

    &:hover {
        background: lighten($primary-dark, 5%);
    }

    &:active,
    &.is-active {
        background: $primary-dark;
    }

    &--secondary {
        background: $secondary;

        &:hover {
            background: lighten($secondary-dark, 5%);
        }

        &:active,
        &.is-active {
            background: $secondary-dark;
        }
    }

    &--round {
        height: 5rem;
        width: 5rem;
        padding: 0;
        border-radius: 50%;
        background: $primary-dark;


        &:active,
        &.is-active {
            background: $secondary;
            box-shadow: 0 4px 4px rgba(#232327, 0.23);
        }
    }


    &--round-sm {
        height: 2.4rem;
        width: 2.4rem;
        padding: 0;
        border-radius: 50%;
        background: $primary-dark;


        &:active,
        &.is-active {
            background: $secondary;
            box-shadow: 0 4px 4px rgba(#232327, 0.23);
        }
    }




    &--card {
        padding: 0 1.5rem;
        height: 3.2rem;
        font-size: 1.4rem;
        text-transform: none;
        font-weight: 400;
        border-radius: 1.6rem;
        background: transparent;

        &:hover {
            background: transparent;
        }


        &:active,
        &.is-active {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
            background: $secondary;
        }

    }


    &--pill {
        padding: 0 1.5rem;
        height: 3.2rem;
        font-size: 1.4rem;
        text-transform: none;
        font-weight: 400;
        border-radius: 1.6rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);


        span {
            font-size: 2rem;
            margin-right: 12px;
        }

        /*
        &:active,
        &.is-active {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
            background: $secondary;
        }*/
    }

}

.link {
    font-size: 1.4rem;
    text-decoration: none;
    border-bottom: 1px dotted;
    color: $main-light;
    cursor: pointer;

    &:hover {
        color: $black;
    }


    &--primary {
        color: $primary;


        &:hover {
            color: $primary-dark;
        }


    }
}

.alert {
    font-size: 1.7rem;
    color: $main;
    border: 1px solid $secondary;
    background: $secondary-light;
    padding: 0 2rem;
    height: 6.4rem;
    border-radius: 1.2rem;
    display: inline-flex;
    align-items: center;

    height: auto;
    min-height: 6.4rem;

    &__icon {
        fill: $black;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1.5rem;
    }
}
