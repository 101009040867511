.sidebar-card {
	display: flex;
	padding: 2rem;
	width: 100%;
	cursor: pointer;
	box-shadow: 0 1px 10px rgba($primary-light, .41);
	border-radius: 1.2rem;

	&__icon{
		margin-right: 2rem;
	}
	&__text{}
	&__title{
		font-size: 2rem;
		font-weight: 700;
		display: flex;
		align-items: center;
	}
	&__icon {
		margin-left: 1rem;
		position: relative;
		top: 2px;
	}
	&__descr{
		color: $main-light;
		font-size: 1.4rem;
		margin-bottom: .5rem;
	}
	&__list{
		.link {
			font-size: 1.7rem;
		}

	}
	&__cnt{
		font-size: 1.4rem;
		margin-left: .5rem;
	}
	&__item {
		margin-bottom: 1rem;
	}

	&.is-active {
		border: 1px solid $primary-light;
	}
}

